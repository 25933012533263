import { PUBLIC_CLOUDFLARE_ACCOUNT_HASH } from '@lib/cloudflare/consts'

export function cloudflareImageUrl(imageId: string, urlParams?: string) {
  // TODO: Once Cloudflare handles our domain, we should use a custom domain and set a far-future expires policy
  // https://developers.cloudflare.com/images/cloudflare-images/serve-images/serve-images-custom-domains/
  // See https://community.cloudflare.com/t/cloudflare-images-serve-static-assets-with-an-efficient-cache-policy/424809/2
  return `https://imagedelivery.net/${PUBLIC_CLOUDFLARE_ACCOUNT_HASH}/${imageId}/${
    urlParams ?? 'public'
  }`
}
