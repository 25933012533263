import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import lowerCase from 'lodash/lowerCase'

export function isNotPlacebo(treatmentName: string) {
  return !hasPlaceboLabel(lowerCase(treatmentName))
}

function hasPlaceboLabel(label: string) {
  return label.toLowerCase().includes('placebo')
}

function hasPlaceboArmGroupType(
  intervention: ArrayElement<TrialInDatabaseForAlgolia['interventions']>,
) {
  return intervention.armGroupsJoin.some(
    (armGroupJoin) =>
      armGroupJoin.armGroup.type === 'PlaceboComparator' ||
      armGroupJoin.armGroup.type === 'ShamComparator',
  )
}

export default function isPlacebo(
  intervention: ArrayElement<TrialInDatabaseForAlgolia['interventions']>,
) {
  return (
    hasPlaceboLabel(intervention.name) || hasPlaceboArmGroupType(intervention)
  )
}
