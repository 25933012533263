import { type PostApplyStep } from '@components/app/ApplySuccessPage/PostApplyPrompt/stepTypes'
import { RoutePrefix } from '.'

const PatientRoutes = {
  applyEligibility: (patientTrialApplicationIdToken: string) =>
    `${RoutePrefix.PATIENTS}/apply-eligibility/${patientTrialApplicationIdToken}`,
  applyPackage: (patientTrialApplicationIdToken: string) =>
    `${RoutePrefix.PATIENTS}/apply-package/${patientTrialApplicationIdToken}`,
  applyPrescreening: (slug: string) =>
    `${RoutePrefix.PATIENTS}/apply-prescreening/${slug}`,
  inbox: `${RoutePrefix.PATIENTS}/inbox`,
  pastApplications: `${RoutePrefix.PATIENTS}/past-applications`,
  patientReferral: (slug: string) =>
    `${RoutePrefix.PATIENTS}/referral/${slug.toLowerCase()}`,
  profile: `${RoutePrefix.PATIENTS}/profile`,
  trialApplyEmail: (slug: string) =>
    `${RoutePrefix.PATIENTS}/trial-apply/${slug}/email`, // v2 apply flow - step 1
  trialApplyEmailCheck: (slug: string) =>
    `${RoutePrefix.PATIENTS}/trial-apply/${slug}/email-check`, // v2 apply flow - step 2
  trialApplyEnglishSpeaking: (slug: string) =>
    `${RoutePrefix.PATIENTS}/trial-apply/${slug}/english`, // v2 apply flow - step 1 - temporary to try and reduce email bounce rate
  trialApplyLegalAge: (slug: string) =>
    `${RoutePrefix.PATIENTS}/trial-apply/${slug}/legal-age`, // v2 apply flow - step 1 - temporary to try and reduce email bounce rate
  trialApplyPatient: (slug: string) =>
    `${RoutePrefix.PATIENTS}/trial-apply/${slug}/patient`, // v2 apply flow - step 3
  trialApplyPreferredApplyPackage: (patientTrialApplicationIdToken: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${patientTrialApplicationIdToken}/preferred-apply-package`, // v2 apply flow - route the user to the preferred apply package
  trialApplyRecommendations: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-recommendations/${token}`, // v2 apply flow - step 12
  trialApplyStart: (slug: string) =>
    `${RoutePrefix.PATIENTS}/trial-apply/${slug}/start`, // v2 apply flow - step 0
  trialApplySuccess: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/success`, // v2 apply flow - step 9
  trialApplySurveyConditions: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/conditions`, // v2 apply flow - step 6
  trialApplySurveyConditionsStep: (token: string, step: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/conditions/${step}`, // v2 apply flow - step 6
  trialApplySurveyDemographics: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/demographics`, // v2 apply flow - step 4
  trialApplySurveyDemographicsStep: (token: string, step: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/demographics/${step}`, // v2 apply flow - step 4
  trialApplySurveyLocations: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/locations`, // v2 apply flow - step 5
  trialApplySurveyPostscreener: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/postscreener`, // v2 apply flow - step 10
  trialApplySurveyPostscreenerConsents: (
    patientTrialApplicationIdToken: string,
    step: PostApplyStep,
  ) =>
    `${RoutePrefix.PATIENTS}/apply-success/${patientTrialApplicationIdToken}/${step}`, // v2 apply flow - collect consent etc
  trialApplySurveyPostscreenerCriterion: (token: string, criterionId: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/postscreener/${criterionId}`, // v2 apply flow - step 11
  trialApplySurveyPrescreener: (token: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/prescreener`, // v2 apply flow - step 7
  trialApplySurveyPrescreenerCriterion: (token: string, criterionId: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/prescreener/${criterionId}`, // v2 apply flow - step 8
  trialApplySurveyPrescreenerVerifier: (token: string, slug: string) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/prescreener/verifiers/${slug}`, // v2 apply flow - step 8.5
  trialApplySurveyPrescreenerVerifierStep: (
    token: string,
    slug: string,
    step: string,
  ) =>
    `${RoutePrefix.PATIENTS}/apply-survey/${token}/prescreener/verifiers/${slug}/${step}`, // v2 apply flow - step 8.75
}

export default PatientRoutes
