import { classMerge } from '@components/utilities/classMerge'
import { cloudflareImageUrl } from '@lib/cloudflare/cloudflareImageUrl'
import pickBy from 'lodash/pickBy'
import Image from 'next/image'

/**
 * Use Cloudflare Images as a storage + delivery mechanism for image assets. Flexible variants is
 * active on the account: https://developers.cloudflare.com/images/cloudflare-images/transform/flexible-variants/
 *
 * @param height Image height, will be right-sized on request with 'fit=cover'
 * @param width Image width, will be right-sized by request with 'fit=cover'
 * @param scaling Use a value > 1 to pull a higher resolution image from cloudflare
 */
export const CloudflareImage = ({
  alt,
  className,
  fit = 'cover',
  height = 500,
  imageId,
  priority = false,
  scaling = 1,
  style,
  title,
  width = 500,
}: {
  alt: string
  className?: string
  fit?: 'cover' | 'contain' | 'fill'
  height?: number
  imageId: string
  priority?: boolean
  scaling?: number
  style?: React.CSSProperties
  title?: string
  width?: number
}) => {
  const cloudflareProps = {
    fit,
    format: 'auto',
    h: height * scaling,
    onerror: 'redirect',
    w: width * scaling,
  }

  const imageUrlParameters = Object.entries(pickBy(cloudflareProps))
    .map(([key, value]) => `${key}=${value}`)
    .join(',')

  const url = cloudflareImageUrl(imageId, imageUrlParameters)

  return (
    <Image
      alt={alt}
      className={classMerge('object-cover', className)}
      height={height}
      priority={priority}
      src={url}
      style={style}
      title={title}
      width={width}
    />
  )
}

export const FullSizedCloudflareImage = ({
  alt,
  className,
  imageId,
  style,
  title,
}: {
  alt: string
  className?: string
  imageId: string
  style?: React.CSSProperties
  title?: string
}) => {
  const cloudflareProps = {
    format: 'auto',
    onerror: 'redirect',
  }

  const imageUrlParameters = Object.entries(pickBy(cloudflareProps))
    .map(([key, value]) => `${key}=${value}`)
    .join(',')

  const url = cloudflareImageUrl(imageId, imageUrlParameters)

  return (
    <Image
      alt={alt}
      className={classMerge('h-auto w-full', className)}
      height='0'
      src={url}
      style={style}
      title={title}
      width='0'
    />
  )
}
