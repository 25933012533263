'use client'

// Adapted from https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
import { useEffect, useState } from 'react'

const TABLET_MAX_WIDTH = 768

function isMobileScreen(width: number = 480) {
  if (typeof window === 'undefined') {
    return false
  }

  return (
    window.matchMedia(`(max-width: ${width}px)`).matches ||
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0
  )
}

export default function useIsMobileScreen() {
  const [isTouch, setIsTouch] = useState(false)
  useEffect(() => {
    const setIsMobile = () => setIsTouch(isMobileScreen())
    setIsMobile()

    window.addEventListener('resize', setIsMobile)
    return () => window.removeEventListener('resize', setIsMobile)
  }, [])

  return isTouch
}

export function useIsTabletOrMobileScreen() {
  const [isTouch, setIsTouch] = useState(isMobileScreen(TABLET_MAX_WIDTH))
  useEffect(() => {
    const setIsTabletOrMobile = () =>
      setIsTouch(isMobileScreen(TABLET_MAX_WIDTH))
    setIsTabletOrMobile()

    window.addEventListener('resize', setIsTabletOrMobile)
    return () => window.removeEventListener('resize', setIsTabletOrMobile)
  }, [])

  return isTouch
}
