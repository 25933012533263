import { Icon, type SizeProp } from '@components/primitives/Icon'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

export const IconPrefix = ({
  children,
  className = 'mr-1 inline',
  icon,
  iconSize,
}: {
  children: React.ReactNode
  className?: string
  icon: IconDefinition
  iconSize?: SizeProp
}) => (
  <>
    <Icon className={className} icon={icon} size={iconSize} />
    {children}
  </>
)
