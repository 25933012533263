import { Icon, type SizeProp } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { ColorGroup } from '@lib/colors/colors'
import { getColorVariant } from '@lib/utilities/colors/getColorVariant'
import truncateString from '@lib/utilities/text/truncateString'
import type { KeyboardEvent, ReactNode } from 'react'

type Variant = 'filled' | 'accent' | 'outlined'

export interface BadgeProps {
  className?: string
  colorGroup?: ColorGroup
  icon?: IconDefinition
  iconClassName?: string
  iconSize?: SizeProp
  label: ReactNode
  maxLabelLength?: number
  onClick?: Function
  strong?: boolean
  textClassName?: string
  variant?: Variant
}

export const Badge = ({
  className,
  colorGroup = 'warm',
  icon,
  iconClassName,
  iconSize,
  label,
  maxLabelLength,
  onClick,
  strong = true,
  textClassName,
  variant = 'filled',
}: BadgeProps) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !!onClick) {
      onClick()
    }
  }

  const isLabelString = typeof label === 'string'
  const labelToDisplay = isLabelString
    ? truncateString(label, maxLabelLength)
    : label

  const colorClasses = getColorVariant(colorGroup, variant)

  const outline = variant === 'outlined' ? 'outline' : ''

  const badgeStyles = classMerge(
    'inline-block whitespace-nowrap rounded-lg px-3 py-1',
    colorClasses,
    className,
    outline,
  )

  return (
    <div
      className={badgeStyles}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      onKeyPress={onClick ? handleKeyPress : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      {icon && <Icon className={iconClassName} icon={icon} size={iconSize} />}
      <Text
        className={classMerge(
          'inline text-center no-underline',
          colorClasses,
          textClassName,
          {
            'cursor-pointer': !!onClick,
          },
        )}
        styleName={strong ? 'p-tiny-strong' : 'p-tiny'}
        value={labelToDisplay}
      />
    </div>
  )
}
