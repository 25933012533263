export type PhaseCategory =
  | 'Phase < 1'
  | 'Phase 1'
  | 'Phase 1 & 2'
  | 'Phase 2'
  | 'Phase 2 & 3'
  | 'Phase 3'
  | 'Phase 4'
  | 'N/A'

export type PhaseSlug =
  | 'early-phase-1'
  | 'phase-1'
  | 'phase-1-and-2'
  | 'phase-2'
  | 'phase-2-and-3'
  | 'phase-3'
  | 'phase-4'
  | ''

type PhaseObject = {
  completedDescription?: string
  completedLabel: string
  description?: string
  label: PhaseCategory
  slug: PhaseSlug
}

const nonePhase: PhaseObject = {
  completedLabel: 'N/A',
  label: 'N/A',
  slug: '',
}

const earlyPhase1: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 1 trials, meaning the treatment is safe for people and we are now testing how effective it is for treating the condition.',
  completedLabel: 'Completed Early Phase 1',
  description:
    'This trial is in Early Phase 1, meaning the treatment has promising results in animals and we are now beginning to test it with people.',
  label: 'Phase < 1',
  slug: 'early-phase-1',
}
const phase1: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 1 trials, meaning the treatment is safe for people and we are now testing how effective it is for treating the condition.',
  completedLabel: 'Completed Phase 1',
  description:
    'This trial is in Phase 1, meaning the treatment has promising results in animals and we are now beginning to test it with people.',
  label: 'Phase 1',
  slug: 'phase-1',
}
const phase1And2: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 2 Trials, meaning the treatment is safe for people and has early promising results for treating the condition.',
  completedLabel: 'Completed Phase 2',
  description:
    'This trial is in Phases 1 & 2, meaning it has promising results in animals and is being evaluated for both safety and its ability to treat the condition.',
  label: 'Phase 1 & 2',
  slug: 'phase-2',
}
const phase2: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 2 Trials, meaning the treatment is safe for people and has early promising results for treating the condition.',
  completedLabel: 'Completed Phase 2',
  description:
    'This trial is in Phase 2, meaning the treatment is safe for people and we are now testing how effective it is for treating the condition.',
  label: 'Phase 2',
  slug: 'phase-2',
}
const phase2And3: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 3 Trials, meaning the US Government has approved the treatment as a safe, effective drug which doctors can prescribe. We are now watching to see if there are any long term side effects.',
  completedLabel: 'Completed Phase 3',
  description:
    'This trial is in Phases 2 & 3, meaning it is safe for people, and is now being tested to see whether it outperforms the best existing treatment for the condition.',
  label: 'Phase 2 & 3',
  slug: 'phase-2-and-3',
}
const phase3: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 3 Trials, meaning the US Government has approved the treatment as a safe, effective drug which doctors can prescribe. We are now watching to see if there are any long term side effects.',
  completedLabel: 'Completed Phase 3',
  description:
    'This trial is in Phase 3, meaning the treatment is safe for people and has early promising results for treating the condition. We are now testing whether it outperforms the best existing treatment for this condition.',
  label: 'Phase 3',
  slug: 'phase-3',
}
export const phase4: PhaseObject = {
  completedDescription:
    'This treatment has completed Phase 4 Trials, meaning the US Government has approved the treatment as a safe, effective drug which doctors can prescribe.',
  completedLabel: 'Completed Phase 4',
  description:
    'This trial is in Phase 4, meaning the US Government has approved the treatment as a safe, effective drug which doctors can prescribe. We are now watching to see if there are any long term side effects.',
  label: 'Phase 4',
  slug: 'phase-4',
}

/**
 * Extracts phase from a list of phases for a trial. Currently, here are all the possibilities:
 * ```
 *        phaseList
 * -----------------------
 *  [NULL]
 *  {"Early Phase 1"}
 *  {"Phase 4"}
 *  {"Phase 1","Phase 2"}
 *  {"Not Applicable"}
 *  {"Phase 3"}
 *  {"Phase 1"}
 *  {"Phase 2","Phase 3"}
 *  {"Phase 2"}
 * (9 rows)
 * ```
 *
 * @param phaseList ClinicalTrial.phaseList is a string array of ['Early Phase 1', 'Phase 1', 'Phase 2', 'Phase 3', 'Phase 4']
 * @returns One of PhaseCategory
 */
export function getPhaseFromPhaseList(phaseList: string[] | null) {
  return parsePhaseFromPhaseList(phaseList).label
}

export function getPhaseSlugFromPhaseList(phaseList: string[] | null) {
  return parsePhaseFromPhaseList(phaseList).slug
}

export const isEarlyPhase1 = (phaseList: string[]) =>
  phaseList.includes('Early Phase 1')
export const isPhase1 = (phaseList: string[]) => phaseList.includes('Phase 1')
export const isPhase2 = (phaseList: string[]) =>
  phaseList.includes('Phase 2') || phaseList.includes('Phase 1 & 2')
export const isPhase3 = (phaseList: string[]) =>
  phaseList.includes('Phase 3') || phaseList.includes('Phase 2 & 3')
export const isPhase4 = (phaseList: string[]) => phaseList.includes('Phase 4')

export const getLatestPhase = (phaseList: string[]): PhaseObject => {
  if (isPhase4(phaseList)) {
    return phase4
  }

  if (isPhase3(phaseList)) {
    return phase3
  }

  if (isPhase2(phaseList)) {
    return phase2
  }

  if (isPhase1(phaseList)) {
    return phase1
  }

  if (isEarlyPhase1(phaseList)) {
    return earlyPhase1
  }

  return nonePhase
}

export function parsePhaseFromPhaseList(
  phaseList: string[] | null,
): PhaseObject {
  if (phaseList === null) {
    return nonePhase
  }

  if (isEarlyPhase1(phaseList)) {
    return earlyPhase1
  }

  if (isPhase1(phaseList) && !isPhase2(phaseList)) {
    return phase1
  }

  if (isPhase1(phaseList) && isPhase2(phaseList)) {
    return phase1And2
  }

  if (isPhase2(phaseList) && !isPhase3(phaseList)) {
    return phase2
  }

  if (isPhase2(phaseList) && isPhase3(phaseList)) {
    return phase2And3
  }

  if (isPhase3(phaseList)) {
    return phase3
  }

  if (isPhase4(phaseList)) {
    return phase4
  }

  return nonePhase
}
