import type { ClinicalTrialCondition, Condition } from '@prisma/client'

export default function isAuthoritativeCondition(
  condition: Pick<ClinicalTrialCondition, 'inConditionsModule'> & {
    condition: Pick<Condition, 'meshId'>
  },
) {
  return condition.inConditionsModule && Boolean(condition.condition.meshId)
}

/**
 * A module condition is a condition that is listed on the clinicalTrials.gov condition module. If the condition was added by a trial edit, we want to consider it to be a module condition.
 */
export function isModuleCondition(
  condition: Pick<ClinicalTrialCondition, 'inConditionsModule' | 'trialEditId'>,
) {
  return condition.inConditionsModule || Boolean(condition.trialEditId)
}
