import isEmptyString from '@lib/utilities/text/isEmptyString'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

export default function extractNumberFromString(
  inputString: string | number | undefined | null,
): number | undefined {
  if (
    isUndefined(inputString) ||
    isNil(inputString) ||
    isEmptyString(inputString)
  ) {
    return
  }

  const parsedAgeString = inputString.toString().replace(/[^0-9]/g, '')

  if (isEmptyString(parsedAgeString)) {
    return
  }

  const maybeNumber = Number(parsedAgeString)

  if (isNaN(maybeNumber)) {
    return
  }

  return maybeNumber
}
