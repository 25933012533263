import ConditionName from '@components/app/ConditionName'
import type { TrialAssociation } from '../types/TrialAssociation'
import { getPrimaryConditionFromTrial } from './getPrimaryConditionFromTrial'
import getPrimaryTreatmentNameFromTrial from './getPrimaryTreatmentNameFromTrial'
import type { TrialForSummaryCard } from './transformTrialForTrialSummaryCard'

/**
 * This function gets the preferred title for a trial. Researchers can modify the "abbreviatedTitle" that should override any inferred trial name (conjured name from gpt, or template string name)
 */
export default function getPreferredTrialTitle(
  trial: Pick<
    TrialAssociation,
    | 'abbreviatedTitle'
    | 'conjuredTitle'
    | 'conditions'
    | 'interventions'
    | 'armGroups'
  >,
  conditionNameOverride?: string | null,
) {
  return (
    trial.abbreviatedTitle ??
    trial.conjuredTitle ??
    `${getPrimaryTreatmentNameFromTrial(trial)} for ${
      conditionNameOverride ??
      ConditionName(getPrimaryConditionFromTrial(trial))
    }`
  )
}

export const getPreferredTrialTitleForAlgoliaTrial = (
  trial: TrialForSummaryCard,
  conditionToDisplay: string,
) => {
  const primaryTreatments = trial.treatments?.filter((t) => t.isPrimary) ?? []

  const [primaryTreatment] = primaryTreatments
  const plusMore =
    primaryTreatments.length > 1 ? ` +${primaryTreatments.length - 1} More` : ''

  const treatmentString = primaryTreatment
    ? `${primaryTreatment.name}${plusMore}`
    : trial.primaryTreatment

  const templateFallback = `${treatmentString} for ${conditionToDisplay}`

  return trial.abbreviatedTitle ?? trial.conjuredTitle ?? templateFallback
}
