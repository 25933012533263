import type { TooltipProps } from '@components/primitives/Tooltip'
import type { ClinicalTrialStatus } from '@prisma/client'

export type StatusCategories = 'Waitlist Available' | ClinicalTrialStatus

export function getTrialStatus(
  overallStatus: ClinicalTrialStatus,
): StatusCategories {
  if (
    overallStatus === 'NotYetRecruiting' ||
    overallStatus === 'ActiveNotRecruiting'
  ) {
    return 'Waitlist Available'
  } else {
    return overallStatus
  }
}

export function getTrialStatusWithDescription(
  overallStatus: 'Recruiting' | 'NotYetRecruiting',
): TooltipProps {
  if (overallStatus === 'NotYetRecruiting') {
    return {
      description:
        'This trial is not currently recruiting, but you can sign up for our waitlist.',
      label: 'Waitlist Available',
    }
  } else {
    return {
      description:
        'This trial is actively recruiting for patients, apply today!',
      label: overallStatus,
    }
  }
}
