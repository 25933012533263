import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment'
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck'
import { faStamp } from '@fortawesome/pro-regular-svg-icons/faStamp'
import { faVialCircleCheck } from '@fortawesome/pro-regular-svg-icons/faVialCircleCheck'
import type { TrialAwardBadgeLabel } from '@modules/trials/etl/ingestTrial/handleTrialAwardBadges/types'

const labelToIcon: Record<TrialAwardBadgeLabel, IconDefinition> = {
  'all-drugs-already-approved': faVialCircleCheck,
  'approved-use-for-other-conditions': faStamp,
  'drug-already-approved': faStamp,
  'no-placebo-group': faStamp,
  'successful-phase-2': faVialCircleCheck,
  'verified-trial-data': faShieldCheck,
}

const tagToIcons = [
  { icon: faStamp, tag: 'efficacy' },
  { icon: faComment, tag: 'general' },
  { icon: faVialCircleCheck, tag: 'safety' },
]

export function getIcon(label: TrialAwardBadgeLabel, tags: string[]) {
  const tagBasedIcon = tagToIcons.find(
    (tagToIcon) => tagToIcon.tag === tags[0],
  )?.icon
  return labelToIcon[label] ?? tagBasedIcon ?? faVialCircleCheck
}
