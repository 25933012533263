import { getAllSearchParams } from '@components/hooks/ParamsProvider/searchParams'

export default function ConditionName(primaryCondition: string | null) {
  const { condition, conditionDisplayName } = getAllSearchParams()

  if (conditionDisplayName) {
    return conditionDisplayName
  } else if (condition) {
    return condition
  }
  return primaryCondition
}
