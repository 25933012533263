import { classMerge } from '@components/utilities/classMerge'

export const EllipsesPulse = ({
  className,
  ellipsesColor,
}: {
  className?: string
  ellipsesColor: 'bg-white' | 'bg-black'
}) => (
  <div className={classMerge('flex h-full items-center gap-2', className)}>
    <div
      className={classMerge('size-2 rounded-full p-1', ellipsesColor)}
      style={{
        animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        animationDelay: '0.1s',
      }}
    />
    <div
      className={classMerge(
        'size-2 animate-pulse rounded-full p-1',
        ellipsesColor,
      )}
      style={{
        animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        animationDelay: '0.2s',
      }}
    />
    <div
      className={classMerge(
        'size-2 animate-pulse rounded-full p-1',
        ellipsesColor,
      )}
      style={{
        animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        animationDelay: '0.3s',
      }}
    />
  </div>
)
