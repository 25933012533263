/**
 * Determine if object is not null, useful for filters. Ensures the returned value is of type T.
 */
export default function isNotNull<T>(
  thing: T | null | undefined | void,
): thing is T {
  if (thing === null || typeof thing === 'undefined') {
    return false
  }

  return true
}
