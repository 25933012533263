import extractNumberFromString from '@lib/utilities/text/extractNumberFromString'
import {
  DEFAULT_UNSET_MAXIMUM_AGE,
  DEFAULT_UNSET_MINIMUM_AGE,
} from '@modules/trialEdits/helpers/ageConstants'
import type { ClinicalTrial } from '@prisma/client'
import isNil from 'lodash/isNil'
import getAgeStringFromAgeList from './getAgeStringFromAgeList'

export default function getAgeStringForTrial(trial: {
  ageList: ClinicalTrial['ageList']
  maximumAge: ClinicalTrial['maximumAge']
  minimumAge: ClinicalTrial['minimumAge']
}) {
  if (isNil(trial.minimumAge) || isNil(trial.maximumAge)) {
    return getAgeStringFromAgeList(trial.ageList)
  }

  const minimumAge = extractNumberFromString(trial.minimumAge)
  const maximumAge = extractNumberFromString(trial.maximumAge)

  const formattedMinimumAge =
    minimumAge === DEFAULT_UNSET_MINIMUM_AGE ? 'No minimum age' : minimumAge
  const formattedMaximumAge =
    maximumAge === DEFAULT_UNSET_MAXIMUM_AGE ? 'No maximum age' : maximumAge

  return `${formattedMinimumAge} - ${formattedMaximumAge}`
}
