import type { Geolocation } from '@lib/types/Geolocation'
import calculateDistance from '@lib/utilities/calculateDistance'
import { isTrialSiteMonetized } from '@modules/facilities/helpers/isTrialSiteMonetized'
import { getPhaseFromPhaseList } from '@modules/trials/helpers/getPhaseFromPhaseList'
import { getTrialStatus } from '@modules/trials/helpers/getTrialStatus'
import type { TrialForSummaryCard } from '@modules/trials/types/TrialForSummaryCard'
import isUndefined from 'lodash/isUndefined'
import type { TrialLocationNarrow } from '../types/TrialAssociatedWithLocations'
import type { TrialAssociation } from '../types/TrialAssociation'
import getAgeStringForTrial from './getAgeStringForTrial'
import {
  cityStatecodeString,
  findClosestLocation,
  locationToPoint,
  sortLocationsByDistance,
} from './parseLocationsFromTrial/parseTruncatedLocationsFromTrial'

export interface TrialAttributes {
  Ages?: string
  Distance?: string
  Duration?: string
  NearestCity?: string
  NearestLocation?: string
  NearestLocationVerified?: boolean
  Phase?: string
  Placebo?: string
  Sexes?: string
  TrialStatus?: string
}

export function getNearbyTrialDistance(
  clinicalTrial: Pick<TrialAssociation | TrialForSummaryCard, 'trialLocations'>,
  userLocation?: Geolocation,
) {
  if (!userLocation) {
    return
  }
  const closestLocationData = findClosestLocation(
    clinicalTrial.trialLocations,
    userLocation,
  )

  if (!closestLocationData?.location) {
    return
  }

  return calculateDistance(
    locationToPoint(closestLocationData.location),
    userLocation,
  )
}

export function prettyDistanceString(distance?: number) {
  // We don't want to highlight this if we are far away
  if (!distance || distance > 80) {
    return ''
  }
  if (distance < 10) {
    return '<10 miles'
  }
  if (distance < 20) {
    return '<20 miles'
  }
  if (distance < 50) {
    return '<50 miles'
  }
  if (distance < 80) {
    return '<80 miles'
  }
}

export function prettyDistanceShort(distance?: number) {
  if (isUndefined(distance) || distance > 99) {
    return '(>99 mi)'
  }
  if (distance < 10) {
    return '(<10 mi)'
  }
  if (distance < 20) {
    return '(<20 mi)'
  }
  if (distance < 50) {
    return '(<50 mi)'
  }
  if (distance < 99) {
    return '(<99 mi)'
  }
}

export const getTrialLocationInfo = (
  locations: TrialLocationNarrow[],
  userLocation?: Geolocation,
) => {
  const [closestTrial] = userLocation
    ? sortLocationsByDistance(locations, userLocation)
    : locations

  const distanceToClosestTrial = getNearbyTrialDistance(
    { trialLocations: locations },
    userLocation,
  )

  return {
    Distance: distanceToClosestTrial
      ? prettyDistanceShort(Math.round(distanceToClosestTrial))
      : undefined,
    NearestCity: closestTrial ? cityStatecodeString(closestTrial) : undefined,
    NearestLocation: closestTrial
      ? `${closestTrial.facility}, ${cityStatecodeString(closestTrial)}`
      : undefined,
    NearestLocationVerified: isTrialSiteMonetized(closestTrial),
  }
}

export function getTrialAttributes(
  clinicalTrial: TrialAssociation | TrialForSummaryCard,
  userLocation?: Geolocation,
): TrialAttributes {
  return {
    Ages: getAgeStringForTrial(clinicalTrial),
    Duration: clinicalTrial.targetDuration ?? undefined,
    ...getTrialLocationInfo(clinicalTrial.trialLocations, userLocation),
    Phase: getPhaseFromPhaseList(clinicalTrial.phaseList),
    Placebo: undefined,
    Sexes: (clinicalTrial.biologicalSex ?? 'All').replace('All', 'All Sexes'),
    TrialStatus: getTrialStatus(clinicalTrial.overallStatus),
  }
}
