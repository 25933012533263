export type AgeCategories =
  | '< 18'
  | '< 65'
  | '18 - 65'
  | '18+'
  | '65+'
  | 'Any Age'

/**
 * Extracts a name from a list of age categories this trial allows. Currently, here are all the possibilities:
 * ```
 *            ageList
 * -----------------------------
 * {"Older Adult"}
 * {Child,Adult}
 * {Child,Adult,"Older Adult"}
 * [NULL]
 * {Adult}
 * {Adult,"Older Adult"}
 * {Child}
 * ```
 *
 * @param ageList ClinicalTrial.ageList is a string array of ['Child', 'Adult', 'Older Adult']
 * @returns An age category, one of AgeCategories: < 18, <18 - 65, 18 - 65, 18+, 65+, Any
 */
export default function getAgeStringFromAgeList(
  ageList?: string | string[] | null,
): AgeCategories {
  if (!ageList || ageList === null) {
    return 'Any Age'
  }

  const containsAdult = ageList.includes('Adult')
  const containsChild = ageList.includes('Child')
  const containsOlderAdult = ageList.includes('Older Adult')

  if (containsChild && containsAdult && containsOlderAdult) {
    return 'Any Age'
  } else if (containsChild && !containsAdult && !containsOlderAdult) {
    return '< 18'
  } else if (containsChild && containsAdult && !containsOlderAdult) {
    return '< 65'
  } else if (!containsChild && containsAdult && !containsOlderAdult) {
    return '18 - 65'
  } else if (!containsChild && containsAdult && containsOlderAdult) {
    return '18+'
  } else if (!containsChild && !containsAdult && containsOlderAdult) {
    return '65+'
  }

  return 'Any Age'
}
